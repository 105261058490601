import { tree } from "@/api/goods/goodscategory"
import { goodsSkuPage } from "@/api/goods/goods";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import { getToken } from "@/utils/auth"
import { addCollect } from "@/api/goods/goods_collect"
import { deleteGoods } from "@/api/member/collection"

export default {
    name: "newIn",
    components: {
        Swiper,
        SwiperSlide
    },
    data: () => {
        return {
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            countrySoyego: localStorage.getItem("countrySoyego"),
            id: 0,
            currentPage: 1,
            pageSize: 40,
            total: 0,
            is_overlay: false,
            order: "",
            sort: "",
            sortList: [],
            sortProductList: [],
            loadLeft: true,
            loadRight: true,
            newInOption: {
				slidesPerView: 4,
				spaceBetween: 30,
				slidesPerGroup: 4,
				loop: true,
				pagination: {
					el: '.swiper-pagination'
			  	},
			},
            newInList: [],
            textAtive: false,
            sortText: 0,
            sizeText: [],
            sizeAtive: false,
            sizeTextList: ['one-size', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL'],
            colorAtive: false,
            colorList: [],
            colorTextList: ['Blue', 'Black', 'Brown', 'Beige', 'Green', 'Gold', 'Grey', 'Multi', 'Pink', 'Purple', 'Red', 'White', 'Yellow', 'Apricot', 'Khaki'],
            slectedId: 0,
            titleRight: '',
            drawer: false,
            direction: 'rtl',
            activeName: 0,
            sortRtlAtive: false,
            lengthText: [],
            lengthTextList: ['Long', 'Maxi', 'Midi', 'Mini', 'Short'],
            sleeveLengthText: [],
            sleeveLengthTextList: ['Cap Sleeve', 'Extra-long Sleeve', 'Half Sleeve', 'Long Sleeve', 'Short Sleeve', 'Sleeveless', 'Three Quarter Length Sleeve'],
            neckLineText: [],
            neckLineTextList: ['Asymmetrical Neck', 'Boat Neck', 'Cold Shoulder', 'Collar', 'Cowl Neck', 'Deep V Neck', 'Halter', 'High Neck', 'V Neck', 'Tie Neck'],
            materialText: [],
            materialTextList: ['Acetate', 'Acrylic', 'Bamboo Fiber', 'Chiffon', 'Corduroy', 'Cotton', 'Cotton Blends', 'Crochet', 'Flannel', 'Giltter', 'Linen', 'Lycan', 'Metallic', 'Velvet'],
            patternTypeText: [],
            patternTypeTextList: ['Argyle-Patterned', 'Block-Coloured', 'Checked', 'Crocodile-Patterned', 'Dogtooth-Patterned', 'Floral', 'Harenquin-Patterned', 'Leoqard-Print', 'Marbled', 'Patterned', 'Pinstriped', 'Soild Colour', 'Spotted', 'Strped'],
            styleText: [],
            styleTextList: ['Basics', 'Boho', 'Casual', 'Cute', 'Dressy', 'Elegant', 'Glamorous', 'Modest', 'Preppy', 'Romantic', 'Sexy', 'Sporty', 'Vintage'],
            sleeveTypeText: [],
            sleeveTypeTextList: ['Balloon Sleeve', 'Batwing Sleeve', 'Butterfly Sleeve', 'Cap Sleeve', 'Dolman Sleeve', 'Flutter Sleeve', 'Puff Sleeve', 'Raglan Sleeve', 'Trumpet Sleeve'],
            priceValue: [0, 1000],
            sortByList: ['Recommended','Most Popular','New Arrivals','Top Reviewed','Price Low To High','Price High To Low'],
            sortByIndex: 0,
            file_list: {
                color: [],
                size: [],
                length: [],
                sleeveLength: [],
                neckLine: [],
                material: [],
                patternType: [],
                style: [],
                sleeveType: []
            },
            likeList: [],
            loadLikeing: true,
            imgTo: "",
            bagShop: false,
            ids: -1,
            filterName: ['1', '2', '3'],
            goodsLoading: true,  // 商品列表Loadings
            // 需要翻译的静态文本
            // translateTextData: {
            //     Items: 'Items',
            //     SORT_BY: 'SORT_BY',
            //     SIZE: 'SIZE',
            //     COLOR: 'COLOR',
            //     Category: 'Category',
            //     Size: 'Size',
            //     Colors: 'Colors',
            //     You_may_also_like: 'You_may_also_like',
            //     View_More: 'View_More',
            //     Recommended: 'Recommended',
            //     Most_Popular: 'Most_Popular',
            //     New_Arrivals: 'New_Arrivals',
            //     Top_Reviewed: 'Top_Reviewed',
            //     Price_Low_To_High: 'Price Low To High',
            //     Price_High_To_Low: 'Price High To Low',
            //     colorTextList: []
            // }
        }
    },
    watch: {
        $route: "fetchData" 
    },
    created() {
        if(this.$route.query){
            if(this.$route.query.categoryId != undefined){
                this.id = this.$route.query.categoryId;
            }
            if(this.$route.query.categoryId2 != undefined){
                this.id = this.$route.query.categoryId2;
            }
            if(this.$route.query.categoryId3 != undefined){
                this.id = this.$route.query.categoryId3;
            }
        }
        // this.getTranslateText();
        this.getGoodsLike(false);
    },
    mounted() {
        this.getTree();
        this.tokenIf();
        this.getGoodsList();
		// this.newInSwiper.slideTo(1, 1000, false)
    },
    computed: {
        swiper () {
			return this.$refs.newInSwiper.swiper
		}
    },
    methods: {
        bagShopClick(stuId) {
			this.ids = stuId;
			this.bagShop = true;
		},
        mouseRight(id, name) {
            // localStorage.removeItem("productDetailsSoyego");
            localStorage.setItem('productDetailsDropSoyego', false);
            localStorage.setItem('productDetailsCategoryNameSoyego', name);
            // localStorage.setItem('productDetailsSoyego', id);
            this.imgTo = "/productDetails?sku_id=" + id;
        },
        // 每日推荐
        getGoodsLike(like) {
            this.loadLikeing = true;
            const params = {
                page: 1,
                page_size: 20,
                attr_rand: true,
                category_id: this.id,
                barterRate: this.countrySoyego
            };
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    let strText = '';
                    data.list.forEach(item => {
                        item.goods_image = item.goods_image.split(',');
                        item.discount_price = Number(item.discount_price).toFixed(2);
                        strText += item.goods_name + '\n';
                    });
                    if(like){
                        this.newInList = this.newInList.concat(data.list);
                    }else{
                        this.newInList = data.list;
                    }
                    // 翻译
                    // this.$fanyi.translateData({
                    //     query: strText,
                    //     success: resData => {
                    //         // like true加载更多数据 以后可能会出现异步，有空优化; false 第一页数据
                    //         if(like){
                    //             data.list.forEach((item,index) => {
                    //                 data.list[index].goods_name = resData[index]
                    //             })
                    //             this.newInList = this.newInList.concat(data.list);
                    //         }else{
                    //             this.newInList.forEach((item,index) => {
                    //                 this.newInList[index].goods_name = resData[index];
                    //             })
                    //         }
                    //     }
                    // })
                    this.loadLikeing = false;
                }
            }).catch(err => {
                this.$message.error(err.message);
                this.loadLikeing = false;
            });
        },
        fetchData() {
            if(this.$route.query){
                if(this.$route.query.categoryId != undefined){
                    this.id = this.$route.query.categoryId;
                }
                if(this.$route.query.categoryId2 != undefined){
                    this.id = this.$route.query.categoryId2;
                }
                if(this.$route.query.categoryId3 != undefined){
                    this.id = this.$route.query.categoryId3;
                }
            }
            this.currentPage = 1;
            this.loadLeft = true;
            this.loadRight = true;
            this.getTree();
            this.tokenIf();
            this.getGoodsList();
            this.getGoodsLike(false);
        },
        tokenIf() {
            let token = getToken();
            if(token == undefined){
                this.is_overlay = false;
            }else{
                this.is_overlay = true;
            }
        },
        collectClick(item) {
            var that = this;
            var token = getToken();
            if(token == undefined){
                this.$router.push({ path: '/login' })
            }else{
                if(item.is_collect == 0) {
                    addCollect({
                        sku_id: item.sku_id,
                        goods_id: item.goods_id,
                        
                    }).then( res => {
                        if(res.code == 0 && res.data){
                            that.sortProductList.forEach(items => {
                                if(items.goods_id == item.goods_id) {
                                    items.is_collect = 1;
                                }
                            });
                            this.$message.success('Collection success');
                        }
                    }).catch( err => {
                        this.$message.error('Collection failed');
                        // console.log(err)
                    })
                }else if(item.is_collect == 1){
                    deleteGoods({
                        goods_id: item.goods_id
                    }).then( res => {
                        if(res.code == 0){
                            that.sortProductList.forEach(items => {
                                if(items.goods_id == item.goods_id) {
                                    items.is_collect = 0;
                                }
                            });
                            this.$message.success('Cancel collection');
                        }
                    }).catch( err => {
                        this.$message.error('Failed to cancel collection');
                        // console.log(err)
                    })
                }
            }
        },
        detailsClick(id, name) {
            localStorage.setItem('productDetailsDropSoyego', false);
            // localStorage.removeItem("productDetailsSoyego");
            localStorage.setItem('productDetailsCategoryNameSoyego', name);
            const blank = this.$router.resolve({path: '/productDetails', query: {sku_id: id}})
            window.open(blank.href,'_blank')
            // this.$router.push({ path: '/productDetails', query: { sku_id: id } });
        },
        clearClick() {
            this.sortText = 0;
            this.sizeText = [];
            this.colorList = [];
            this.lengthText = [];
            this.sleeveLengthText = [];
            this.neckLineText = [];
            this.materialText = [];
            this.patternTypeText = [];
            this.styleText = [];
            this.sleeveTypeText = [];
            this.priceValue = [0, 1000];
        },
        sortChange() {
            switch(this.sortText) {
                case 0: this.order = "";this.sort = "";break;
                case 1: this.order = "sale_num";this.sort = "";break;
                case 2: this.order = "create_time";this.sort = "";break;
                case 3: this.order = "evaluate";this.sort = "";break;
                case 4: this.order = "discount_price";this.sort = "asc";break;
                case 5: this.order = "discount_price";this.sort = "desc";break;
                default : break;
            }
        },
        arrowPrev() {
			this.$refs.newInSwiper.$swiper.slidePrev()
			// this.swiper.slidePrev()
		},
		arrowNext() {
			this.$refs.newInSwiper.$swiper.slideNext()
			// this.swiper.slideNext()
		},
        handleCurrentChange(val) {
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0

            this.currentPage = val
            this.loadRight = true
            this.loadLikeing = true
            this.getGoodsList()
            this.getGoodsLike()            
        },
        claerSizeColor() {
            this.sizeText = [];
            this.file_list.size = this.sizeText;
            this.colorList = [];
            this.file_list.color = this.colorList;
        },
        sizeChange(val) {
            if(this.sizeText.includes(val)){ //es6 判断是否包含
				this.sizeText = this.sizeText.filter(function (item){return item !== val;});
			}else{
				this.sizeText.push(val);
			}
            this.file_list.size = this.sizeText;
            this.screenClick();
        },
        colorChange(val) {
            if(this.colorList.includes(val)){ //es6 判断是否包含
				this.colorList = this.colorList.filter(function (item){return item !== val;});
			}else{
				this.colorList.push(val);
			}
            this.file_list.color = this.colorList;
            this.screenClick();
        },
        lengthChange(val) {
            if(this.lengthText.includes(val)){ //es6 判断是否包含
				this.lengthText = this.lengthText.filter(function (item){return item !== val;});
			}else{
				this.lengthText.push(val)
			}
            this.file_list.length = this.lengthText;
        },
        sleeveLengthChange(val) {
            if(this.sleeveLengthText.includes(val)){ //es6 判断是否包含
				this.sleeveLengthText = this.sleeveLengthText.filter(function (item){return item !== val;});
			}else{
				this.sleeveLengthText.push(val)
			}
            this.file_list.sleeveLength = this.sleeveLengthText;
        },
        neckLineChange(val) {
            if(this.neckLineText.includes(val)){ //es6 判断是否包含
				this.neckLineText = this.neckLineText.filter(function (item){return item !== val;});
			}else{
				this.neckLineText.push(val)
			}
            this.file_list.neckLine = this.neckLineText;
        },
        materialChange(val) {
            if(this.materialText.includes(val)){ //es6 判断是否包含
				this.materialText = this.materialText.filter(function (item){return item !== val;});
			}else{
				this.materialText.push(val)
			}
            this.file_list.material = this.materialText;
        },
        patternTypeChange(val) {
            if(this.patternTypeText.includes(val)){ //es6 判断是否包含
				this.patternTypeText = this.patternTypeText.filter(function (item){return item !== val;});
			}else{
				this.patternTypeText.push(val)
			}
            this.file_list.patternType = this.patternTypeText;
        },
        styleChange(val) {
            if(this.styleText.includes(val)){ //es6 判断是否包含
				this.styleText = this.styleText.filter(function (item){return item !== val;});
			}else{
				this.styleText.push(val)
			}
            this.file_list.style = this.styleText;
        },
        sleeveTypeChange(val) {
            if(this.sleeveTypeText.includes(val)){ //es6 判断是否包含
				this.sleeveTypeText = this.sleeveTypeText.filter(function (item){return item !== val;});
			}else{
				this.sleeveTypeText.push(val)
			}
            this.file_list.sleeveType = this.sleeveTypeText;
        },
        handleClose() {
            this.drawer = false;
        },
        handleClose1() {
            this.bagShop = false;
        },
        categoryClick(val) {
            // console.log(val)
            this.id = val;
            this.loadRight = true;
            this.getLeftTree();
            this.getGoodsList();
        },
        getLeftTree(){
            if(this.sortList.category_id == this.id){
                this.slectedId = this.sortList.category_id;
                this.titleRight = this.sortList.category_name;
            }else{  
                this.sortList.child_list.forEach(item => {
                    if(item.category_id == this.id){
                        this.slectedId = item.category_id;
                        this.titleRight = item.category_name;
                    }
                })
            }
        },
        getTree() {
            tree({
                level: 3,
                // category_id: this.id
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    if(this.$route.query){
                        if(this.$route.query.categoryId != undefined){
                            res.data.forEach(item => {
                                if(item.category_id == this.id){
                                    this.sortList = item;
                                    this.slectedId = item.category_id;
                                    this.titleRight = item.category_name;
                                }
                            });
                        }
                        if(this.$route.query.categoryId2 != undefined){
                            res.data.forEach(item => {
                                if(item.child_list != undefined){
                                    item.child_list.forEach(item1 => {
                                        if(item1.category_id_2 == this.id){
                                            this.sortList = item1;
                                            this.slectedId = item1.category_id_2;
                                            this.titleRight = item1.category_name;
                                        }
                                    });
                                }
                            });
                        }
                        if(this.$route.query.categoryId3 != undefined){
                            res.data.forEach(item => {
                                if(item.child_list != undefined){
                                    item.child_list.forEach(item1 => {
                                        if(item1.child_list != undefined){
                                            item1.child_list.forEach(item2 => {
                                                if(item2.category_id_3 == this.id){
                                                    this.sortList = item2;
                                                    this.slectedId = item2.category_id_3;
                                                    this.titleRight = item2.category_name;
                                                }
                                            });
                                        }
                                    });
                                }
                                
                            });
                        }
                    }
                    this.loadLeft = false;
                }
            }).catch((err) => {
                this.loadLeft = false;
                this.$message.error(err.message)
            })
        },
        //获取商品列表
        getGoodsList(like) {
            this.goodsLoading = true;
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                is_overlay: this.is_overlay,
                category_id: this.id,
                order: this.order,
                sort: this.sort,
                file_list: this.file_list,
                min_price: this.priceValue[0],
                max_price: this.priceValue[1],
                barterRate: this.countrySoyego
            };
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0 && res.data) {
                    let data = res.data;
                    data.list.forEach(item => {
                        item.category_id = item.category_id.split(',');
                        item.goods_image = item.goods_image.split(',');
                        item.goods_spec_format = JSON.parse(item.goods_spec_format);
                        item.discount_price = Number(item.discount_price).toFixed(2);
                        if (item.goods_spec_format != null) {
                            this.$set(item.goods_spec_format[0], 'colorSoyego', item.goods_spec_format[0].value[0].sku_id);
                            item.goods_spec_format[0].colorIndex = 0;
                        }
                        this.$set(item, 'imgHover', false);
                    });
                    if(like){
                        this.sortProductList = this.sortProductList.concat(data.list);
                    }else{
                        this.sortProductList = data.list;
                    }
                    this.total = res.data.count;
                    this.goodsLoading = false;

                    this.loadRight = false;
                }
            }).catch(err => {
                this.loadRight = false;
                this.$message.error(err.message);
            });
        },

        // 翻译静态文本
        // getTranslateText() {
        //     let strText = '';
        //     let i = 0;
        //     let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
        //     translateData.forEach((item,index) => {
        //         strText += item + '\n'
        //     })
        //     // 翻译颜色
        //     // this.getTransColor()
        //     // 翻译
        //     this.$fanyi.translateData({
        //         query: strText,
        //         success: resData => {
        //             for(let key in this.translateTextData){
        //                 this.translateTextData[key] = resData[i]
        //                 i++;
        //             }
        //         }
        //     })
        // },
        // 翻译颜色
        // getTransColor() {
        //     let strText = '';
        //     let strTextList = this.colorTextList;
        //     this.colorTextList.forEach((item,index) => {
        //         strText += item + '\n'
        //     })
        //     this.$fanyi.translateData({
        //         query: strText,
        //         success: resData => {
        //             this.colorTextList.forEach((item,index) => {
        //                 this.translateTextData.colorTextList.push(resData[index])
        //             })
                    
        //         }
        //     })
        // },
        // 筛选颜色/规格
        screenClick() {
            this.drawer = false;
            this.loadRight = true;
            this.loadLikeing = true;
            this.sortChange();
            this.getGoodsList();
        },

        // 筛选sortBy选项
        sortClick(val) {
            this.loadRight = true;
            this.loadLikeing = true;
            this.sortText = val;
            this.sortByIndex = val;
            this.sortChange();
            this.getGoodsList();
        },

        // 点击列表商品的颜色
        switchGoodsColor(index,cIndex,sku_id) {
            var that = this;
            that.sortProductList[index].goods_spec_format[0].colorIndex = cIndex;
            that.sortProductList[index].sku_id = sku_id
        }
        
    }
}
