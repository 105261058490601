 <template>
    <div class="newIn">
        <div class="newIn-header">
            <div class="header-container">
                <div class="header-top">
                    <div class="top-title">CLOTHING</div>
                    <div class="top-items">{{ total }} Items</div>
                </div>
                <div class="header-menu">
                    <div class="menu-left">
                        <div class="left-color">
                            <el-dropdown class="el-dropdown" @command="colorChange">
                                <span class="el-dropdown-link">
                                    COLOR<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="dropdown-list">
                                    <el-dropdown-item 
                                    class="dropdown-item" 
                                    v-for="(item,index) in colorTextList"
                                    :command="item">{{ item }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div class="line">
                                <el-image :src="require('@/assets/images/newIn/right_line.png')" fit="cover" />
                            </div>
                        </div>
                        <div class="left-size">
                            <el-dropdown class="el-dropdown" @command="sizeChange">
                                <span class="el-dropdown-link">
                                    SIZE<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="dropdown-list">
                                    <el-dropdown-item 
                                    class="dropdown-item" 
                                    v-for="(item,index) in sizeTextList"
                                    :command="item">{{ item }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <!-- <div class="left-filier">
                            <div class="text">FILIER</div>
                            <div class="icon">
                                <el-image :src="require('@/assets/images/newIn/down.png')" fit="cover" />
                            </div>
                        </div> -->
                    </div>
                    <div class="menu-right">
                        <div class="right-title">SORT BY:</div>
                        <el-dropdown class="el-dropdown" @command="sortClick">
                            <span class="el-dropdown-link">
                                {{ sortByList[sortByIndex] }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dropdown-list" trigger="click">
                                <el-dropdown-item 
                                class="dropdown-item" 
                                v-for="(item,index) in sortByList"
                                :command="index">{{ item }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="newIn-main" v-loading="goodsLoading">
            <div class="main-goosList">
                <div class="goodsList-item" v-for="(item,index) in sortProductList" >
                    <div class="item-cover" @click="detailsClick(item.sku_id, item.category_name)">
                        <el-image :src="item.sku_image" fit="cover" />
                    </div>
                    <div class="item-goodsName">{{ item.goods_name }}</div>
                    <div class="item-price">{{ item.str_cur_x }}{{ item.price }}</div>
                    <div class="item-goodsColor">
                        <div class="goodsColor-item"
                        v-for="(c_item,c_index) in item.goods_spec_format[0].value"
                        :class="c_index==item.goods_spec_format[0].colorIndex?'cur':''"
                        :title="c_item.spec_value_name"
                        @click="switchGoodsColor(index,c_index,c_item.sku_id)">
                            <el-image :src="c_item.image" fit="cover" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-more">
                <div class="more-button" @click="getGoodsList(true)">
                    <span>View More</span>
                </div>
            </div>
        </div>
        <!-- 猜你喜欢 -->
        <div class="newIn-recommen" v-if="sortProductList.length > 0" v-loading="loadLikeing">
            <div class="recommen-container">
                <div class="recommen-header">
                    <span>You may also like</span>
                </div>
                <div class="recommen-goodsList">
                    <div class="goodsList-item" v-for="(item,index) in newInList">
                        <div class="item-cover" @click="detailsClick(item.sku_id, item.category_name)">
                            <el-image :src="$img(item.sku_image)" fit="cover" />
                        </div>
                        <div class="item-goodsName">{{item.sku_name}}</div>
                        <div class="item-price">{{item.str_cur_x}}{{item.price}}</div>
                    </div>
                </div>
                <div class="recommen-more">
                    <div class="more-button" @click="getGoodsLike(true)">
                        <span>View More</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* .newIn .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #330000;
    color: #fff;
} */
/* .newIn .el-collapse{
    border: none;
}
.newIn .el-collapse-item__wrap{
    border: none;
}
.newIn .el-collapse-item__header{
    border: none;
}
.newIn .el-slider__stop{
    background-color: #ECEEEE;
}
.newIn .el-drawer.ltr, .newIn .el-drawer.rtl, .newIn .el-drawer__container{
    overflow-y: auto;
}
.newIn .el-collapse-item__header {
    font-size: 18px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #330000;
}
.newIn .el-drawer__close-btn {
    outline: none;
}
.newIn .el-drawer__open .el-drawer.rtl {
    outline: none;
}
.newIn .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #330000;
    color: #fff;
}
.newIn .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #330000;
    color: #fff;
}
.newIn .el-pagination.is-background .btn-prev,
.newIn .el-pagination.is-background .btn-next,
.newIn .el-pagination.is-background .el-pager li {
    background: transparent;
    color: #330000;
}
.newIn .newIn-main .el-loading-mask {
    background-color: rgba(247, 249, 250, 0.7);
}
.newIn .newIn-main .el-loading-spinner {
    top: 250px;
}
.about-content {
    background: #f7f9fa;
} */
</style>

<style lang="scss" scoped>
// @import "./newIn/newIn.scss";
</style>

<style lang="scss" scoped>
    .newIn{
        width: 100%;
        .newIn-header{
            width: 100%;
            display: flex;
            justify-content: center;
            .header-container{
                width: 88.54vw;
                display: flex;
                flex-direction: column;
                padding-top: 40px;
                .header-top{
                    display: flex;
                    align-items: center;
                    padding: 12px 0px;
                    border-bottom: 1px solid #efefef;
                    .top-title{
                        font-family: Poppins-Medium;
                        font-weight: 500;
                        color: rgba(51, 51, 51, 1);
                        font-size: 42px;
                        line-height: 42px;
                    }
                    .top-items{
                        color: rgba(132, 126, 126, 1);
                        font-size: 14px;
                        line-height: 14px;
                        font-family: Poppins-Regular;
                        margin-left: 30px;
                    }
                }
                .header-menu{
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 0px;
                    border-bottom: 1px solid #efefef;
                    .menu-left{
                        display: flex;
                        .left-color{
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            text{
                                color: rgba(51, 51, 51, 1);
                                font-size: 16px;
                                line-height: 16px;
                                font-family: Poppins-Regular;
                            }
                            .icon{
                                width: 10px;
                                height: 6px;
                                margin-left: 10px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                            .line{
                                width: 2px;
                                height: 10px;
                                margin-left: 16px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                        .left-size{
                            display: flex;
                            align-items: center;
                            margin-left: 16px;
                            cursor: pointer;
                            text{
                                color: rgba(51, 51, 51, 1);
                                font-size: 16px;
                                line-height: 16px;
                                font-family: Poppins-Regular;
                            }
                            .icon{
                                width: 10px;
                                height: 6px;
                                margin-left: 12px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                            .line{
                                width: 2px;
                                height: 10px;
                                margin-left: 16px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                        .left-filier{
                            display: flex;
                            align-items: center;
                            margin-left: 16px;
                            cursor: pointer;
                            text{
                                color: rgba(51, 51, 51, 1);
                                font-size: 16px;
                                line-height: 16px;
                                font-family: Poppins-Regular;
                            }
                            .icon{
                                width: 10px;
                                height: 6px;
                                margin-left: 12px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                    }
                    .menu-right{
                        display: flex;
                        align-items: center;
                        .right-title{
                            color: rgba(132, 126, 126, 1);
                            font-size: 14px;
                            line-height: 14px;
                            font-family: Poppins-Regular;
                        }
                        .el-dropdown{
                            margin-left: 10px;
                            cursor: pointer;
                            .dropdown-list{
                                .dropdown-item{
                                    &:hover{
                                        background-color: #330000;
                                    }
                                }
                            }
                        }
                        .right-sortBy{
                            display: flex;
                            align-items: center;
                            margin-left: 20px;
                            cursor: pointer;
                            .sortBy-text{
                                color: rgba(51, 51, 51, 1);
                                font-size: 16px;
                                line-height: 16px;
                                font-family: Poppins-Regular;
                            }
                            .sortBy-icon{
                                width: 10px;
                                height: 6px;
                                margin-left: 12px;
                                position: relative;
                                .el-image{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .newIn-main{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .main-goosList{
                width: 88.54vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-bottom: 60px;
                .goodsList-item{
                    width: 21.35vw;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 40px;
                    cursor: pointer;
                    .item-cover{
                        width: 21.35vw;
                        height: calc(21.35vw * 540 / 410);
                        background-color: aqua;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item-goodsName{
                        width: 18vw;
                        text-align: center;
                        color: rgba(132, 126, 126, 1);
                        line-height: 20px;
                        min-height: 42px;
                        font-size: 16px;
                        font-family: Poppins-Regular;
                        margin-top: 20px;
                        /* 1.溢出隐藏 */
                        overflow: hidden;
                        /* 2.用省略号来代替超出文本 */
                        text-overflow: ellipsis;
                        /* 3.设置盒子属性为-webkit-box  必须的 */
                        display: -webkit-box;
                        /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                        -webkit-line-clamp: 2;
                        /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                        word-break: break-all;
                        /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                        -webkit-box-orient: vertical;
                    }
                    .item-price{
                        color: rgba(51, 0, 0, 1);
                        font-size: 16px;
                        font-family: Poppins-Medium;
                        font-weight: 500;
                        margin-top: 10px;
                    }
                    .item-goodsColor{
                        display: flex;
                        align-items: center;
                        margin-top: 12px;
                        .goodsColor-item{
                            width: 18px;
                            height: 18px;
                            cursor: pointer;
                            margin-left: 12px;
                            padding: 1px;
                            &:first-child{
                                margin-left: 0;
                            }
                            &.cur{
                                border: 1px solid #333;
                            }
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .main-more{
                width: 100%;
                display: flex;
                justify-content: center;
                margin:20px 0px;
                .more-button{
                    width:22.30vw;
                    height:calc(22.30vw * 64 / 428);
                    background-color: #330000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    span{
                        color:#fff;
                        font-size:18px;
                        font-weight: 500;
                    }
                }
            }
        }
        .newIn-recommen{
            width: 100%;
            padding: 8vh 0px;
            display: flex;
            justify-content: center;
            .recommen-container{
                width: 93.95vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                .recommen-header{
                    width: 24.42vw;
                    height: calc(24.42vw * 84 / 469);
                    border: 1px solid #000;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    span{
                        color: rgba(51, 0, 0, 1);
                        font-size: 26px;
                    }
                }
                .recommen-goodsList{
                    width: 93.95vw;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    // margin-top: 60px;
                    .goodsList-item{
                        width: 17.70vw;
                        display: flex;
                        flex-direction: column;
                        margin-top: 50px;
                        .item-cover{
                            width: 17.70vw;
                            height: calc(17.70vw * 454 / 340);
                            background-color: #330000;
                            cursor:pointer;
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .item-goodsName{
                            color: rgba(51, 51, 51, 1);
                            font-size: 16px;
                            line-height: 20px;
                            min-height: 42px;
                            margin-top: 22px;
                            /* 1.溢出隐藏 */
                            overflow: hidden;
                            /* 2.用省略号来代替超出文本 */
                            text-overflow: ellipsis;
                            /* 3.设置盒子属性为-webkit-box  必须的 */
                            display: -webkit-box;
                            /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                            -webkit-line-clamp: 2;
                            /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                            word-break: break-all;
                            /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                            -webkit-box-orient: vertical;
                            text-align: center;
                        }
                        .item-price{
                            color: rgba(51, 0, 0, 1);
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: 500;
                            margin-top: 10px;
                            text-align: center;
                        }
                    }
                }
                .recommen-more{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top:40px;
                    .more-button{
                        width:22.30vw;
                        height:calc(22.30vw * 64 / 428);
                        background-color: #330000;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        span{
                            color:#fff;
                            font-size:18px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
</style>

<script>
import newIn from "./newIn/newIn.js"
import BagShop from "@/views/shop/components/bagShop.vue"

export default {
    name: "newIn",
    components: {
        BagShop
    },
    mixins: [newIn]
}
</script>